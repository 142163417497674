import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {HttpClient} from "@angular/common/http";
import {ReferralService} from "../../../@core/referral.service";
import {NotificationMenuComponent} from "../../notification/notification-menu.component";

@Component({
	selector: 'app-notification-confirmations-cancel',
	templateUrl: './notification-confirmations.component.html'
})
export class NotificationConfirmationsComponent implements OnInit {
	title = 'StSr Message';
	message = '';
	messageHTML = '';
	showButtons = true;
	confirmText="Confirm"

	globalSaveService;

	constructor(
		private http: HttpClient,
		public dialog: MatDialog,
		private dialogRef: MatDialogRef<NotificationConfirmationsComponent>,
		private dialogRef1: MatDialogRef<NotificationMenuComponent>,
		private referralService: ReferralService,
		@Inject(MAT_DIALOG_DATA) private data: any,
		) {

	}

	ngOnInit() {
		this.message = this.data.message;
		this.messageHTML = this.data.messageHTML;
		if(this.data.showButtons!=undefined && this.data.showButtons!=null){
			this.showButtons = this.data.showButtons;
		}
		if(this.data.confirmText){
			this.confirmText = this.data.confirmText;
		}
	}

	closeVisitForm() {
		this.dialogRef.close({status: false});
	}

	confirm() {
		this.dialogRef.close({status: true});
	}

}

