import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DynamicFormService, } from '@ng-dynamic-forms/core';
import { ADDRESS, CYTY, EXT, PHONE, PRESS, STATE_SELECT, STRING_FIELD, ZIP } from '../../../../../../../@util/dynamic-form/dynamic-controlls';
import { MedicalService } from '../../../../../../@core/medical.service';
import { FormValueBuilder } from '../../../../../partial/forms/form-tamplate';
import { MedicalTreatingSourcesComponent } from '../../medical-treating-sources.component';
import { ActivatedRoute, Router } from '@angular/router';
import { map, startWith } from 'rxjs/operators';
import { CatalogItemType } from '../../../../../../@models/medical/catalog-item-view';
import { NavigationService } from "../../../../../../@core/navigation.service";
import { AddressService } from "../../../../../../@core/address.service";
import { State } from "../../../../../../test-new-stepper-form/test-form/on-changes";
import { Functions } from "../../../../../../../@util/functions";
import { DynamicFormGroupModel } from "../../../../../../../@util/ui-material/form-controls";
import { UserInfoService } from "../../../../../../@core/user-info.service";
import { MedicalRequestsComponent } from "../../medical-requests/medical-requests.component";
import { MedicalPaymentsComponent } from "../../medical-payments/medical-payments.component";
import { GlobalSaveService } from "../../../../../../@core/global-save.service";
import { PERMISSIONS } from 'src/app/@config/permissions';
var HospitalFormComponent = /** @class */ (function () {
    function HospitalFormComponent(medicalService, formService, addressService, userInfoService, globalSaveService, 
    // private dialogRef: MatDialogRef<HospitalFormComponent>,
    // @Inject(MAT_DIALOG_DATA) private data: { sslgId: string, mode: FormMode, id?: number }
    _parent, route, router, navService) {
        var _this = this;
        this.medicalService = medicalService;
        this.formService = formService;
        this.addressService = addressService;
        this.userInfoService = userInfoService;
        this.globalSaveService = globalSaveService;
        this._parent = _parent;
        this.route = route;
        this.router = router;
        this.navService = navService;
        this.title = 'New Hospital';
        this.PERMISSIONS = PERMISSIONS;
        this.formLayout = {
            phones: {
                element: {
                    container: 'row-mb',
                    control: 'form-row',
                }
            },
            contact_phone: {
                element: {
                    host: 'form-element form-element_x2'
                }
            },
            contact_press: {
                element: {
                    host: 'form-element'
                }
            },
            contact_ext: {
                element: {
                    host: 'form-element'
                }
            },
            contact_fax: {
                element: {
                    container: 'row-mb'
                }
            },
            contact_address: {
                element: {
                    container: 'row-mb'
                }
            },
            contact_address2: {
                element: {
                    container: 'row-mb'
                }
            },
            contact_cyti: {
                element: {
                    container: 'row-mb'
                }
            },
            state_zip: {
                element: {
                    control: 'form-row',
                    container: 'row-mb'
                }
            },
            contact_state: {
                element: {
                    host: 'form-element'
                }
            },
            contact_zip: {
                element: {
                    host: 'form-element'
                }
            }
        };
        this.formTemplate = {
            name: STRING_FIELD({ id: 'name', label: 'Name', hidden: true, editableFor: PERMISSIONS.LOWER_EXEC }),
            recipient: {
                recipientName: STRING_FIELD({
                    id: 'recipientName',
                    label: 'Person To Contact',
                    maxLength: 128,
                    required: true,
                    validators: { required: null },
                    editableFor: PERMISSIONS.LOWER_EXEC
                }),
            },
            contact: {
                phone: PHONE({ id: 'contact_phone', label: 'Phone', editableFor: PERMISSIONS.LOWER_EXEC }),
                press: PRESS({ id: 'contact_press', label: '"Press"', editableFor: PERMISSIONS.LOWER_EXEC }),
                ext: EXT({ id: 'contact_ext', label: 'Ext.', editableFor: PERMISSIONS.LOWER_EXEC }),
                fax: PHONE({ id: 'contact_fax', label: 'Business Fax', editableFor: PERMISSIONS.LOWER_EXEC }),
                street: ADDRESS({ id: 'contact_address', editableFor: PERMISSIONS.LOWER_EXEC }),
                street2: ADDRESS({ id: 'contact_address2', label: 'Address 2', editableFor: PERMISSIONS.LOWER_EXEC }),
                city: CYTY({ id: 'contact_cyti', editableFor: PERMISSIONS.LOWER_EXEC }),
                state: STATE_SELECT({ id: 'contact_state', editableFor: PERMISSIONS.LOWER_EXEC }),
                zip: ZIP({ id: 'contact_zip', editableFor: PERMISSIONS.LOWER_EXEC }),
            }
        };
        this.form = {
            name: this.formTemplate.name,
            recipientName: this.formTemplate.recipient.recipientName,
            contact: DynamicFormGroupModel({
                id: 'contact',
                group: [
                    DynamicFormGroupModel({
                        id: 'phones',
                        group: [
                            this.formTemplate.contact.phone,
                            this.formTemplate.contact.press,
                            this.formTemplate.contact.ext
                        ],
                        editableFor: PERMISSIONS.LOWER_EXEC
                    }),
                    this.formTemplate.contact.fax,
                    DynamicFormGroupModel({
                        id: 'state_zip',
                        group: [
                            this.formTemplate.contact.zip,
                            this.formTemplate.contact.state,
                        ],
                        editableFor: PERMISSIONS.LOWER_EXEC
                    }),
                    this.formTemplate.contact.city,
                    this.formTemplate.contact.street,
                    this.formTemplate.contact.street2,
                ],
                editableFor: PERMISSIONS.LOWER_EXEC
            }),
        };
        this.subscribed = true;
        this.state = State.before;
        this.allFormState = State.before;
        this.tabNavigationActive = 0;
        this.nameCtrl = new FormControl();
        this.names = [];
        this.formModel = FormValueBuilder.buildFormModel(this.form);
        this.formGroup = this.formService.createFormGroup(this.formModel);
        this.nameCtrl.setValidators(Validators.required);
        this.sub = navService.back().subscribe(function (j) { return _this.closeDoctorForm(); });
    }
    HospitalFormComponent.prototype.ngOnDestroy = function () {
        this._parent.hideHeader = false;
        this.sub.unsubscribe();
    };
    HospitalFormComponent.prototype._filterNames = function (value) {
        ////console.log(value);
        var filterValue = typeof value === 'string' ? value.toLowerCase() : value.name ? value.name.toLowerCase() : '';
        ////console.log(filterValue);
        return this.names.filter(function (state) { return state.name.toLowerCase().indexOf(filterValue) === 0; });
    };
    HospitalFormComponent.prototype.displayFn = function (item) {
        return item ? item.name : undefined;
    };
    HospitalFormComponent.prototype.ngAfterViewInit = function () {
    };
    HospitalFormComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, result, e_1;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        // if(this.userInfoService.isStaff() || this.userInfoService.isSslgStaff()){
                        this.navLinks = [
                            {
                                id: 'info',
                                label: 'Info'
                            },
                            {
                                id: 'requests',
                                label: 'Requests & Submissions'
                            },
                        ];
                        // }else{
                        // 	this.navLinks = [
                        // 		{
                        // 			id: 'info',
                        // 			label: 'Info'
                        // 		}
                        // 	];
                        // }
                        setTimeout(function () {
                            _this._parent.hideHeader = true;
                        });
                        this.sslgId = this.route.snapshot.parent.params['sslgId'];
                        if (!(this.route.snapshot.url[1].path === 'new')) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.medicalService.getCatalog(CatalogItemType.HOSPITAL).toPromise()];
                    case 1:
                        _a.names = _b.sent();
                        this.filteredNames = this.nameCtrl.valueChanges
                            .pipe(map(function (value) {
                            _this.hospital = null;
                            _this.formTemplate.name.valueUpdates.next(_this.nameCtrl.value + '');
                            if (value.id) {
                                _this.subscription.unsubscribe();
                                if (value.id != -1) {
                                    _this.formGroup.disable({ onlySelf: true });
                                    setTimeout(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                        var _a;
                                        return tslib_1.__generator(this, function (_b) {
                                            switch (_b.label) {
                                                case 0:
                                                    _a = this;
                                                    return [4 /*yield*/, this.medicalService.getCatalogItem(CatalogItemType.HOSPITAL, value.id).toPromise()];
                                                case 1:
                                                    _a.hospital = _b.sent();
                                                    return [4 /*yield*/, this.setFormData()];
                                                case 2:
                                                    _b.sent();
                                                    return [2 /*return*/];
                                            }
                                        });
                                    }); });
                                }
                                else {
                                    _this.addressService.getPlaceInfo(value.placeId).subscribe(function (j) {
                                        _this.formTemplate.name.autocompleteSelectedValue = null;
                                        if (j.zip)
                                            _this.formTemplate.contact.zip.valueUpdates.next(j.zip);
                                        if (j.state)
                                            _this.formTemplate.contact.state.valueUpdates.next(j.state);
                                        if (j.city)
                                            _this.formTemplate.contact.city.valueUpdates.next(j.city);
                                        if (j.locations)
                                            _this.formTemplate.contact.street.valueUpdates.next((j.streetNumber ? j.streetNumber + ' ' : '') + (j.locations ? j.locations : ''));
                                    });
                                }
                            }
                            else {
                                if (!_this.subscribed) {
                                    _this.medicalService.setMedicalConditionsTemplate([]);
                                    _this.subscribeZipCode();
                                }
                                _this.formGroup.enable({ onlySelf: true });
                                setTimeout(function () {
                                    _this.formGroup.reset();
                                });
                            }
                            return value;
                        }), startWith(''), map(function (state) {
                            _this.onStateChanges();
                            if (state) {
                                var names_1 = _this._filterNames(state);
                                if (typeof state === 'string' && state.length > 3 || state.name) {
                                    var query = typeof state === 'string' ? state : state.name;
                                    _this.addressService.findPlace(query, "HOSPITAL").pipe(map(function (place) {
                                        return place.map(function (j) {
                                            return tslib_1.__assign({ id: -1, name: j.title, label: j.description }, j);
                                        });
                                    })).subscribe(function (j) {
                                        names_1.push.apply(names_1, tslib_1.__spread(j));
                                        //console.log(this.filteredNames)
                                    });
                                }
                                return names_1;
                            }
                            return _this.names.slice();
                        }));
                        this.subscribeZipCode();
                        return [3 /*break*/, 8];
                    case 2:
                        this.title = 'Info Hospital';
                        _b.label = 3;
                    case 3:
                        _b.trys.push([3, 5, , 6]);
                        return [4 /*yield*/, this.medicalService.getCatalogItemInfo(this.sslgId, this.route.snapshot.paramMap.get('id')).toPromise()];
                    case 4:
                        result = _b.sent();
                        this.hospital = result.catalog;
                        return [3 /*break*/, 6];
                    case 5:
                        e_1 = _b.sent();
                        this.router.navigate([this.route.snapshot.queryParams['returnUrl']], { queryParams: { page: this.route.snapshot.queryParams['page'] } });
                        return [3 /*break*/, 6];
                    case 6: 
                    // FormValueBuilder.setData(this.hospital.contact, this.contactTamplate);
                    // setTimeout(() => {
                    //     this.nameCtrl.patchValue(this.hospital.name);
                    // });
                    return [4 /*yield*/, this.setFormData()];
                    case 7:
                        // FormValueBuilder.setData(this.hospital.contact, this.contactTamplate);
                        // setTimeout(() => {
                        //     this.nameCtrl.patchValue(this.hospital.name);
                        // });
                        _b.sent();
                        setTimeout(function () {
                            _this.formTemplate.name.hidden = false;
                            if (!_this.globalSaveService.getStatusTrue() || _this.isClaimant()) {
                                //this.formGroup.disable({onlySelf: false});
                                //this.nameCtrl.disable({onlySelf: false});
                            }
                        });
                        if (this.hospital.createdByClaimant)
                            document.getElementById('name').classList.add('claimant-badge');
                        _b.label = 8;
                    case 8:
                        this.oldFormData = FormValueBuilder.getData(this.form);
                        this.oldSourceNameData = this.nameCtrl.value ? this.nameCtrl.value : '';
                        return [2 /*return*/];
                }
            });
        });
    };
    HospitalFormComponent.prototype.showBlock = function (i) {
        this.tabNavigationActive = i;
        document.querySelectorAll('.blocks-tab').forEach(function (el) {
            el.style.display = 'none';
        });
        document.getElementById('block-tab-' + this.navLinks[this.tabNavigationActive].id).style.display = '';
    };
    HospitalFormComponent.prototype.blockTabActive = function (i) {
        if (i == this.tabNavigationActive) {
            return true;
        }
        return false;
    };
    HospitalFormComponent.prototype.isCreationPage = function () {
        if (this.route.snapshot.url[1].path === 'new') {
            return true;
        }
        return false;
    };
    HospitalFormComponent.prototype.subscribeZipCode = function () {
        var _this = this;
        this.subscription = this.formTemplate.contact.zip.valueUpdates.subscribe(function (j) {
            if (j && j.length == 5) {
                _this.addressService.findAddress(j).subscribe(function (addr) {
                    if (addr && addr.state) {
                        _this.formTemplate.contact.state.valueUpdates.next(addr.state);
                        _this.formTemplate.contact.city.valueUpdates.next(addr.city);
                    }
                });
            }
            _this.subscribed = true;
        });
    };
    HospitalFormComponent.prototype.showName = function () {
        // if(this.globalSaveService.getStatusTrue() && !this.isClaimant()){
        // 	return true;
        // }
        // return this.route.snapshot.url[1].path === 'new'
        if (!this.globalSaveService.getStatusTrue() && !this.isClaimant()) {
            return false;
        }
        return true;
    };
    HospitalFormComponent.prototype.showBtn = function () {
        if (!this.globalSaveService.getStatusTrue() && !this.isClaimant()) {
            return false;
        }
        return true;
    };
    HospitalFormComponent.prototype.setFormData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                setTimeout(function () {
                    if (_this.hospital !== null) {
                        try {
                            var street = _this.hospital.contact.street ? _this.hospital.contact.street : '';
                            _this.hospital.contact.street = street.replace(/,/g, '');
                            var name_1 = _this.hospital.name ? _this.hospital.name : '';
                            var fax = (_this.hospital.contact && _this.hospital.contact.fax) ? _this.hospital.contact.fax : '';
                            _this.hospitalContacts = {
                                name: name_1,
                                fax: fax
                            };
                        }
                        catch (e) {
                        }
                        FormValueBuilder.setData(_this.hospital, _this.formTemplate);
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    HospitalFormComponent.prototype.onBlur = function ($event) {
    };
    HospitalFormComponent.prototype.onChange = function ($event) {
    };
    HospitalFormComponent.prototype.onFocus = function ($event) {
    };
    HospitalFormComponent.prototype.closeDoctorForm = function () {
        this.router.navigate([this.route.snapshot.queryParams['returnUrl']], { queryParams: { page: this.route.snapshot.queryParams['page'] } });
        // this.dialogRef.close();
    };
    HospitalFormComponent.prototype.saveDoctor = function (cmd) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, doc;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        FormValueBuilder.setControlsToched(this.formGroup);
                        data = FormValueBuilder.getData(this.formTemplate);
                        if (!this.hospital) {
                            data.name = typeof this.nameCtrl.value === 'string' ? this.nameCtrl.value : this.nameCtrl.value.name;
                            data.googlePlaceId = this.nameCtrl.value.placeId ? this.nameCtrl.value.placeId : null;
                        }
                        data.sslgId = this.sslgId;
                        //if (this.route.snapshot.url[1].path !== 'new') {
                        data.id = +this.route.snapshot.paramMap.get('id');
                        return [4 /*yield*/, this.medicalService.saveTreatingSourcesHospital(this.sslgId, data, this.hospital)];
                    case 1:
                        doc = _a.sent();
                        if (doc) {
                            if (cmd == 'new') {
                                this.state = State.before;
                                this.router.navigate([this.route.snapshot.queryParams['returnUrl']], { queryParams: { page: this.route.snapshot.queryParams['page'] } });
                            }
                            else {
                                this.state = State.before;
                                this.router.navigate(['/claimants/medical/' + this.sslgId + '/visit-summaries/new'], { queryParams: {
                                        returnUrl: this.route.snapshot.queryParams['returnUrl'],
                                        catalogItemId: doc.id,
                                        type: 'HOSPITAL',
                                    } });
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    HospitalFormComponent.prototype.onStateChanges = function () {
        var dataNew = FormValueBuilder.getData(this.form);
        var sourceNew = this.nameCtrl.value ? this.nameCtrl.value : '';
        if (this.oldFormData != '' && this.oldSourceNameData != '') {
            var isChanges = Functions.equals(this.oldFormData, dataNew);
            var isChangesSource = Functions.equals(this.oldSourceNameData, sourceNew);
            if (!isChanges || !isChangesSource) {
                this.state = State.after;
            }
            else {
                this.state = State.before;
            }
        }
    };
    HospitalFormComponent.prototype.isInputDirty = function () {
        return this.state == State.after;
    };
    HospitalFormComponent.prototype.addVisit = function () {
        this.saveDoctor('addVisit');
    };
    HospitalFormComponent.prototype.isClaimant = function () {
        return this.userInfoService.isClaimant();
    };
    HospitalFormComponent.prototype.getBtnTitle = function () {
        var title = 'Add a New Medical Source';
        if (this.route.snapshot.url[1].path !== 'new') {
            title = 'Update a Medical Source';
        }
        return title;
    };
    HospitalFormComponent.prototype.getBtnName = function () {
        if (this.route.snapshot.url[1].path !== 'new') {
            return 'Update';
        }
        return 'Add';
    };
    HospitalFormComponent.prototype.checkDisable = function () {
        if (!this.globalSaveService.isClient()) {
            return this.globalSaveService.checkDisabledFields(this.form);
        }
        return true;
    };
    return HospitalFormComponent;
}());
export { HospitalFormComponent };
