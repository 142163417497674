/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../partial/form-actions-content/form-actions-content.component.ngfactory";
import * as i2 from "../../partial/form-actions-content/form-actions-content.component";
import * as i3 from "../../partial/form-button/form-button.component.ngfactory";
import * as i4 from "../../partial/form-button/form-button.component";
import * as i5 from "../../partial/forms/form-frame/form-frame.component.ngfactory";
import * as i6 from "../../partial/forms/form-frame/form-frame.component";
import * as i7 from "@angular/common";
import * as i8 from "./notification-confirmations.component";
import * as i9 from "@angular/common/http";
import * as i10 from "@angular/material/dialog";
import * as i11 from "../../../@core/referral.service";
var styles_NotificationConfirmationsComponent = [];
var RenderType_NotificationConfirmationsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NotificationConfirmationsComponent, data: {} });
export { RenderType_NotificationConfirmationsComponent as RenderType_NotificationConfirmationsComponent };
function View_NotificationConfirmationsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["\n\t\t", "\n\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 1, 0, currVal_0); }); }
function View_NotificationConfirmationsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.messageHTML; _ck(_v, 0, 0, currVal_0); }); }
function View_NotificationConfirmationsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "app-form-actions-content", [], null, null, null, i1.View_FormActionsContentComponent_0, i1.RenderType_FormActionsContentComponent)), i0.ɵdid(1, 114688, null, 0, i2.FormActionsContentComponent, [], null, null), (_l()(), i0.ɵted(-1, 0, ["\n\t\t"])), (_l()(), i0.ɵeld(3, 0, null, 0, 2, "form-button", [], null, [[null, "clickOnButton"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickOnButton" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_FormButtonComponent_0, i3.RenderType_FormButtonComponent)), i0.ɵdid(4, 638976, null, 0, i4.FormButtonComponent, [], null, { clickOnButton: "clickOnButton" }), (_l()(), i0.ɵted(5, 0, ["", ""])), (_l()(), i0.ɵted(-1, 0, ["\n\t\t"])), (_l()(), i0.ɵeld(7, 0, null, 0, 2, "form-button", [["buttonType", "warning"]], null, [[null, "clickOnButton"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickOnButton" === en)) {
        var pd_0 = (_co.closeVisitForm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_FormButtonComponent_0, i3.RenderType_FormButtonComponent)), i0.ɵdid(8, 638976, null, 0, i4.FormButtonComponent, [], { buttonType: [0, "buttonType"] }, { clickOnButton: "clickOnButton" }), (_l()(), i0.ɵted(-1, 0, ["Cancel"])), (_l()(), i0.ɵted(-1, 0, ["\n\t"]))], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 4, 0); var currVal_1 = "warning"; _ck(_v, 8, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.confirmText; _ck(_v, 5, 0, currVal_0); }); }
export function View_NotificationConfirmationsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "app-frame", [["autocomplete", "off"]], null, [[null, "neadClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("neadClose" === en)) {
        var pd_0 = (_co.closeVisitForm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_FormFrameComponent_0, i5.RenderType_FormFrameComponent)), i0.ɵdid(1, 114688, null, 0, i6.FormFrameComponent, [], { title: [0, "title"], autocomplete: [1, "autocomplete"], overlay: [2, "overlay"] }, { neadClose: "neadClose" }), (_l()(), i0.ɵted(-1, 0, ["\n\t"])), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_NotificationConfirmationsComponent_1)), i0.ɵdid(4, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, 0, ["\n\t"])), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_NotificationConfirmationsComponent_2)), i0.ɵdid(7, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, 0, ["\n\t"])), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_NotificationConfirmationsComponent_3)), i0.ɵdid(10, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, 0, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; var currVal_1 = "off"; var currVal_2 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = !_co.messageHTML; _ck(_v, 4, 0, currVal_3); var currVal_4 = _co.messageHTML; _ck(_v, 7, 0, currVal_4); var currVal_5 = _co.showButtons; _ck(_v, 10, 0, currVal_5); }, null); }
export function View_NotificationConfirmationsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-notification-confirmations-cancel", [], null, null, null, View_NotificationConfirmationsComponent_0, RenderType_NotificationConfirmationsComponent)), i0.ɵdid(1, 114688, null, 0, i8.NotificationConfirmationsComponent, [i9.HttpClient, i10.MatDialog, i10.MatDialogRef, i10.MatDialogRef, i11.ReferralService, i10.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationConfirmationsComponentNgFactory = i0.ɵccf("app-notification-confirmations-cancel", i8.NotificationConfirmationsComponent, View_NotificationConfirmationsComponent_Host_0, {}, {}, []);
export { NotificationConfirmationsComponentNgFactory as NotificationConfirmationsComponentNgFactory };
